import "@aws-amplify/ui-react/styles.css";
import React, {useEffect, useState} from 'react';
import {ApiClient} from "../services/api";
import {Card, Table} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import {Loading} from "../components/Loading";

const SeasonCups = () => {

    const [cups, setCups] = useState([]);
    

    useEffect(() => {
        const fetch = async () => {
            const api = new ApiClient()

            const data = await api.get('season/cups');

            setCups(data);
            
            console.log(cups)
        }

        fetch()

    }, []);

    if(!cups) return <Loading/>
    
    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title>Season League Cups</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>League Cup</th>
                        </tr>
                        </thead>
                        <tbody>
                        {cups.map((cup: any) => (
                            <tr key={cup.cup.id}>
                                <td style={{width: '10%'}} className=''>
                                    <NavLink to={`/dashboard/cup/${cup.cup.id}`} className='nav-link'>
                                        {cup.cup.name}
                                    </NavLink>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>

                </Card.Body>
            </Card>           
        </>

    )
}

export default SeasonCups;
