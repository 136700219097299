import React from "react";
import {Link} from "react-router-dom";
import {SidebarLink} from "./SidebarLink";

export const Sidebar = () => {
  return <>
      <div className="sidebar border border-right col-md-3 col-lg-2 p-0 bg-body-tertiary">
          <div className="offcanvas-md offcanvas-end bg-body-tertiary" id="sidebarMenu"
               aria-labelledby="sidebarMenuLabel">
              <div className="offcanvas-header">
                  <h5 className="offcanvas-title" id="sidebarMenuLabel">Guild Warden</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="offcanvas"
                          data-bs-target="#sidebarMenu" aria-label="Close"></button>
              </div>
              <div className="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto">
                  <ul className="nav flex-column">
                      <SidebarLink navigationUrl={`/dashboard/guild`} title='Dashboard'/>
                      <SidebarLink navigationUrl={`/dashboard/roster`} title='Roster'/>
                      <SidebarLink navigationUrl={`/dashboard/inventory`} title='Inventory'/>
                      <SidebarLink navigationUrl={`/dashboard/repairs`} title='Repairs'/>
                      <SidebarLink navigationUrl={`/dashboard/tavern`} title='Tavern'/>
                      <SidebarLink navigationUrl={`/dashboard/vendor`} title='Vendor'/>
                  </ul>

                  <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-body-secondary text-uppercase">
                      <span>Season</span>
                      <a className="link-secondary" href="#" aria-label="Add a new report">
                          <svg className="bi">
                              <use xlinkHref="#plus-circle"></use>
                          </svg>
                      </a>
                  </h6>
                  <ul className="nav flex-column mb-auto">

                      <li className="nav-item">
                          <Link className="nav-link d-flex align-items-center gap-2 active"
                                to={`/dashboard/season/leagues`}>Leagues</Link>
                      </li>

                      <li className="nav-item">
                          <Link className="nav-link d-flex align-items-center gap-2 active"
                                to={`/dashboard/season/cups`}>Leagues Cups</Link>
                      </li>

                      <li className="nav-item">
                          <Link className="nav-link d-flex align-items-center gap-2 active"
                                to={`/dashboard/season/championship/groups`}>Championship Groups</Link>
                      </li>

                      <li className="nav-item">
                          <Link className="nav-link d-flex align-items-center gap-2 active"
                                to={`/dashboard/season/championship/knockouts`}>Championship Knockouts</Link>
                      </li>
                      
                  </ul>

              </div>
          </div>
      </div>

  </>
}