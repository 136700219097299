import React, {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {useAuthenticator} from '@aws-amplify/ui-react'
import Landing from "../pages/Landing";
import {MasterLayout} from "../layout/MasterLayout";
import {CreateGuild} from "../pages/CreateGuild";
import {GuildPage} from "../pages/GuildPage";
import {RosterPage} from "../pages/RosterPage";
import SeasonLeagues from "../pages/SeasonLeagues";
import {League} from "../pages/League";
import SeasonCups from "../pages/SeasonCups";
import {Cup} from "../pages/Cup";
import ChampionshipGroupsView from "../pages/ChampionshipGroupsView";
import {Inventory} from "../pages/Inventory";
import {RepairsPage} from "../pages/RepairsPage";
import {BattlePage} from "../pages/BattlePage";
import {TavernView} from "../pages/TavernView";
import ChampionshipKnockoutsView from "../pages/ChampionshipKnockoutsView";
import {RosterHeroView} from "../pages/RosterHeroView";
import {TavernHeroView} from "../pages/TavernHeroView";
import {VendorView} from "../pages/VendorView";



const AppRoutes: FC = () => {
  const {authStatus} = useAuthenticator((context) => [context.authStatus])
    
  return (
    <BrowserRouter>
      <Routes>
          {authStatus === 'authenticated' ? (
            <>
                    <Route element={<MasterLayout  />}>
                        <Route path='/dashboard/guild' element={<GuildPage />} />
                        <Route path='/dashboard/roster' element={<RosterPage />} />
                        <Route path='/dashboard/inventory' element={<Inventory actions={[]} />} />
                        <Route path='/dashboard/repairs' element={<RepairsPage  />} />
                        <Route path='/dashboard/vendor' element={<VendorView  />} />
                        <Route path='/dashboard/tavern' element={<TavernView  />} />
                        <Route path='/dashboard/tavern/hero/:heroId' element={<TavernHeroView  />} />
                        <Route path='/dashboard/season/leagues' element={<SeasonLeagues />} />
                        <Route path='/dashboard/season/cups' element={<SeasonCups />} />
                        <Route path='/dashboard/season/championship/groups' element={<ChampionshipGroupsView />} />
                        <Route path='/dashboard/season/championship/knockouts' element={<ChampionshipKnockoutsView />} />
                        <Route path='/dashboard/league/:leagueId'  element={<League />}/>
                        <Route path='/dashboard/roster/hero/:heroId'  element={<RosterHeroView />}/>
                        <Route path='/dashboard/battle/:battleId'  element={<BattlePage />}/>
                        <Route path='/dashboard/cup/:cupId'  element={<Cup />}/>
                    </Route>
                    <Route path='/' element={<CreateGuild />} />
             
            </>
          ) : (
            <>
              <Route path='/' element={<Landing  />} />
              <Route path='/*' element={<Landing />} />
            </>
          )}
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
