import React from "react";
import {Header} from "./Header";
import {Sidebar} from "./Sidebar";
import {Content} from "./Content";

export const MasterLayout = () => {
    return <>

        <Header/>

        <div className="container-fluid">
            <div className="row">
                <Sidebar/>
                <Content/>
            </div>
        </div>


    </>
}