import { Auth } from 'aws-amplify';
import React, {useEffect, useState} from "react";
import {Container, Dropdown, Nav, Navbar, NavDropdown} from 'react-bootstrap';
import {Link} from "react-router-dom";


export const Header = () => {

    const [userName, setUsername] = useState();
    
    useEffect(() => {
        getLoggedInUser();
    }, []);
    
    const getLoggedInUser = async () => {
        const data = await Auth.currentUserInfo();
        setUsername(data.attributes.email)
    }

    const signOut = async () => {
        await Auth.signOut();
    }
    
    return <>
      <header className="navbar sticky-top bg-dark flex-md-nowrap p-0 shadow" data-bs-theme="dark">
          <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6 text-white" href="#">Guild Warden</a>
          <Nav >
              <NavDropdown
                  id="nav-dropdown-dark-example"
                  title={userName}
                  menuVariant="dark"
              >
                  <NavDropdown.Item onClick={signOut}>Logout</NavDropdown.Item>
              </NavDropdown>
              
              <NavDropdown
                  id="nav-dropdown-dark-example"
                  title="menu"
                  menuVariant="dark"
              >
                  <NavDropdown.Item as={Link} to={`/dashboard/guild`}>Dashboard</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={`/dashboard/roster`}>Roster</NavDropdown.Item>
              </NavDropdown>
          </Nav>
      </header>
  </>
}