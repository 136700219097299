import React, {useEffect, useState} from "react";
import {Guild, Item, Vendor} from "../global";
import {ApiClient} from "../services/api";
import {Loading} from "../components/Loading";
import {Button, Card, Table} from "react-bootstrap";
import {toast} from "react-toastify";

export const VendorView = () => {
    
    const [vendor, setVendor] = useState<Vendor>()
    const [guild, setGuild] = useState<Guild>();

    useEffect(() => {
        const fetch = async () => {
            const client = new ApiClient()
            
            const vendorResponse = await client.get('vendor');
            
            setVendor(vendorResponse);

            const guildResponse = await client.get('guild')
            setGuild(guildResponse);
        }
        
        fetch()
        
    }, []);
    
    if(!vendor || !guild) return <Loading/>

    const buy = async (item: Item) => {
        if(guild?.coins >= item.value) {

            guild.coins = guild.coins - item.value;

            setGuild({...guild});
            
            vendor.items[item.name].shift()
            
            setVendor({...vendor})

            toast.info(`Bought ${item.name}`);

            const api = new ApiClient();

            await api.post('guild/buyitem', {
                itemName: item.name
            });
        }

        else {
            toast.error(`Not enough coins!`);
        }
    };

    return <> 
        <Card>
            <Card.Header>
                <Card.Title>Vendor</Card.Title>
            </Card.Header>
            <Card.Body>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Item</th>
                        <th>Cost</th>
                        <th>Stock</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.entries(vendor.items).map(([key, items]) => (
                        
                        <tr key={key}>
                            <td style={{width: '10%'}} className=''>{key}</td>
                            <td>{items.length > 0 ? items[0].value : 0}</td>
                            <td>{items.length}</td>
                            <td>
                                <Button disabled={items.length === 0} onClick={() => buy(items[0])} name="Buy" className="btn btn-primary btn-sm"/>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>

    </>
}