import {Authenticator} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import React from 'react';

const Landing = () => {
    
    return (
        <>
            <div className="container col-xxl-8 px-4 py-5">
                <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                    <div className="col-10 col-sm-8 col-lg-6">
                        <Authenticator loginMechanisms={['email']} socialProviders={['google']}
                                       signUpAttributes={['email']}>
                        </Authenticator>
                    </div>
                    <div className="col-lg-6">
                        <h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3">Guild Warden</h1>
                        <p className="lead">Build. Recruit. Battle</p>

                    </div>
                </div>
            </div>
        </>

    )
}

export default Landing;
