import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {Amplify} from 'aws-amplify'
import 'bootstrap/dist/css/bootstrap.min.css';
import './dashboard.css'
import {App} from "./App";


Amplify.configure({
    Auth: {
        region: 'eu-west-1',
        userPoolId: process.env.REACT_APP_USER_POOL,
        userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
        oauth: {
            domain: process.env.REACT_APP_USER_POOL_DOMAIN,
            redirectSignIn: process.env.REACT_APP_USER_POOL_REDIRECT_SIGN_IN,
            redirectSignOut: process.env.REACT_APP_USER_POOL_REDIRECT_SIGN_IN,
            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
    },
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <App/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
