import {PageContent} from "../../../ux/Card";
import {ReadOnlyField} from "../../../ux/ReadOnlyField";
import React from "react";
import {Hero} from "../../../global";

type Props = {
    hero: Hero
}

export const HeroDetails = (props: Props) => {
    
    const hero = props.hero;
    
    return <PageContent title="Hero" children={
            <form>
                <ReadOnlyField label="Name" value={hero.name}/>
                <ReadOnlyField label="Strength" value={hero.strength}/>
                <ReadOnlyField label="Attack Rating" value={hero.attackRating}/>
                <ReadOnlyField label="Defence Rating" value={hero.defenceRating}/>
            </form>
    }></PageContent>
}